import Axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from "axios";
import { getAuthToken, redirectToLogout } from "../utils/authUtils";
import LocalAuth from "@shamrock-core/common/authentication/local-authentication";

export abstract class ServiceBase {
  axios: AxiosInstance;

  authenticate: boolean;

  constructor(serviceUrl: string, authenticate: boolean = true) {
    this.axios = Axios.create({
      baseURL: serviceUrl,
    });
    this.authenticate = authenticate;
  }
}

export default class Service extends ServiceBase {
  async addInterceptors(
    isAuth0Enabled: boolean,
    tokenGenerator?: () => Promise<string>,
    logout?: () => Promise<void>
  ) {
    this.axios.interceptors.request.use(
      async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
        if (!this.authenticate) return config;
        config.headers = config.headers || {};
        const token = getAuthToken();
        if (token) {
          // @ts-ignore
          config.headers.Authorization = `Bearer ${token}`;
        } else if (tokenGenerator) {
          const generatedToken = await tokenGenerator();
          config.headers.Authorization = `Bearer ${generatedToken}`;
        }
        return config;
      },
      (error: AxiosError) => Promise.reject(error)
    );

    this.axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if ([401, 403].includes(error?.response?.status)) {
          const token = getAuthToken();
          if (!token && isAuth0Enabled && logout) {
            await logout();
          } else {
            LocalAuth.clearStorage();
            redirectToLogout();
          }
        }
        return Promise.reject(new Error(error));
      }
    );
  }
}
